import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';

const International_zone = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[addzone ,setaddzone] = React.useState({zone_name:""})
    const[state , setstate]=React.useState([])

    React.useEffect(()=>{
        group_list()
    },[])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   

      const handleChange =(e)=>{
        setaddzone({...addzone , [e.target.name]:e.target.value})
      }
      const group_list = ()=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/international_zone_list`;
        let sendData = { }
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                setstate(res.data.output)
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
     
       }
      const create_zone = ()=>{
        if(addzone.zone_name!=""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_international_zone`;
        let sendData = { zone_name:addzone.zone_name }
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Added Successfully",
                    color: "white"
                });
                window.location.reload()
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
        }else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Please Fill Group Name",
                color: "white"
            });
        }
       }
      
       
  return (
    <div>
      
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
              
       <Sub_header/>


<div style={{ marginLeft: "15px", marginRight: "15px" }}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                  

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">Add International Zone</h2>
                        </div>
                    </div>
                </div>
              
            </div>
            
    </section>

    <div class="modal" id="addnewpincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Zone</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Zone Name: <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="" placeholder="Enter Name" name="zone_name" onChange={(e) => handleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary" onClick={(e)=>create_zone(e)} >Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    <section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            
    
          
                <div class="row mb-3">
                    
                    <div class="col-md text-end ">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addnewpincode_special" data-bs-backdrop="false">Add Zone</button>
                        {state.length>0?
                        <a class="btn btn-primary ms-2" href="/assign_international_zone">Assign Zone</a>
                        :""}
                       
                    </div>
                </div>
            
                  <div class="">
                <table class="table table-hover  table-responsive">
                    <thead>
                        <tr>
                            <th class="border-gray-200">#</th>	
                            <th class="border-gray-200">Zone Name</th>
                        </tr>
                    </thead>

                    <tbody>
                        {state.map((sub , i)=>(
                        <tr>
                            <td>
                                {i+1}
                            </td>                     
                            <td>
                              {sub.international_zone_name}
                            </td> 
                        </tr> 
                        ))}
                                            
                    </tbody>
                   
                </table>
            </div> 
         
          </div>
        </div>
    </section>
    </div>
    </section>
    </div>
  )
}

export default International_zone
