import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';

const Group_pincodes = () => {
    let {zone_id,group_id} = useParams()
    console.log("zone_id" , zone_id)
    console.log("group_id" , group_id)
    let navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const[addgroup, setaddgroup] = React.useState({pincode:""})
    const[state , setstate]=React.useState([])
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });

    React.useEffect(()=>{
        group_list()
    },[])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        // source_destinationlist(newPage);
        
        // searchUser(state.searchValue,newPage)
      }
      const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
      }

      const handleChange =(e)=>{
        setaddgroup({...addgroup , [e.target.name]:e.target.value})
      }
      const group_list = (index=0)=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/group_pincodes`;
        let sendData = {indexValue:index,
            limit:otherStates.rowsPerPage , group_id:group_id}
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                if(index==0  && otherStates.onload) {
                    console.log("res === " , res)
                    setOtherState({...otherStates,total_count:res.data.count})         
          }
                setstate(res.data.output)
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
     
       }
      const create_pincode = ()=>{
        if(addgroup.pincode!=""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_group_pincode`;
        let sendData = { group_id:group_id , zone_id:zone_id , pincode:addgroup.pincode}
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: res.data.message,
                    color: "white"
                });
                window.location.reload()
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: res.data.message,
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
        }else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Please Fill Pincode",
                color: "white"
            });
        }
       }
       const remove = (postal_code)=>{
      
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/remove_zone_group_pincode`;
        let sendData = { group_id:group_id , zone_id:zone_id.zone_id , postal_code:postal_code}
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Remove Successfully",
                    color: "white"
                });
                setTimeout(()=>{
                    window.location.reload()
                },1000)
                
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       
       }
       
       const csvinputEditHandleChange = (e) => {        
        if (e.target.type == "file") {            
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        } else {
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
        }
      }

      const uploadPincodeFile= () => {

        if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
            let fileName = uploadModalState.excel_fle[0].name;
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_group_bulk_pincode`;
         let bodyFormData = new FormData()
         bodyFormData.append("zone_id", zone_id)
         bodyFormData.append("group_id", group_id)
         bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
        
         console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);

            setUploadModalState({...uploadModalState, show: false });
            if (res.status == 200 && res.data.status == "success") {
               const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Bulk Import Process Starts",
                    color: "white"
                });
                setTimeout(()=>{
                  navigate(`/group_import_process/${zone_id}/${group_id}`)
                },1000)
             }
              else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }
            }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })
        });
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
   

}
  return (
    <div>
      
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>


<div style={{ marginLeft: "15px", marginRight: "15px" }}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                  

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">Group Pincodes</h2>
                        </div>
                    </div>
                </div>
              
            </div>
            
    </section>

    <div class="modal" id="import_pincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Bulk Pincode </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="../../../assets/doc/Group_pincodes.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
       
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    <div class="modal" id="addnewpincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Pincode</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Pincode: <span class="text-danger">*</span></label>
          <input type="number" class="form-control" id="" placeholder="Enter Name" name="pincode" onChange={(e) => handleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary" onClick={(e)=>create_pincode(e)} >Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    <section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            
    
            <section>
                <div class="row mb-3">
                    
                    <div class="col-md text-end ">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addnewpincode_special" data-bs-backdrop="false">Add Pincode</button>
                        <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#import_pincode_special" data-bs-backdrop="false">Import Bulk Pincode</button>
                        {/* <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#Import_Log" data-bs-backdrop="false">Import Log</button> */}
                    </div>
                </div>
                <table class="table table-bordered">
                  
                  
                    <thead class="bg-dark">
                      <tr>
                        <th className="text-white">Pincode</th>
                        {/* <th className="text-white">Group Name</th> */}
                        <th className="text-white">Action</th>
                      
                      </tr>
                    </thead>
                <tbody>
                    {state.map((sub)=>(
                        <tr>
                         <td>{sub.postal_code}</td>
                         {/* <td>{sub.group_name}</td> */}
                         <td class="pin_delte">
                            <button type="button" class="btn btn-danger btn-sm" onClick={(e)=>remove(sub.postal_code)}><i class="material-icons-outlined pin_delte fs-12">delete</i> </button>
                        </td>
                         </tr>
                  ))}
                </tbody>
               
                </table>
            </section>

            

<TablePagination
                component="div"
                rowsPerPageOptions={[5,10]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
          </div>
        </div>
    </section>
    </div>
    </section>
    </div>
  )
}

export default Group_pincodes
