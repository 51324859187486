import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';

const Local_zone = () => {
    let zone_id = useParams()
    console.log("zone_id" , zone_id)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const[addgroup, setaddgroup] = React.useState({group_name:""})
    const[state , setstate]=React.useState([])

    React.useEffect(()=>{
        group_list()
    },[])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        // source_destinationlist(newPage);
        
        // searchUser(state.searchValue,newPage)
      }
      const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
      }

      const handleChange =(e)=>{
        setaddgroup({...addgroup , [e.target.name]:e.target.value})
      }
      const group_list = ()=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/zone_group_list`;
        let sendData = { }
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                setstate(res.data.output)
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
     
       }
      const create_group = ()=>{
        if(addgroup.group_name!=""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_zone_group`;
        let sendData = { group_name:addgroup.group_name , zone_id:zone_id.zone_id}
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Added Successfully",
                    color: "white"
                });
                window.location.reload()
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
        }else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Please Fill Group Name",
                color: "white"
            });
        }
       }
       const remove = (group_id)=>{
      
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/remove_zone_group`;
        let sendData = { group_id:group_id , zone_id:zone_id.zone_id}
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Remove Successfully",
                    color: "white"
                });
                setTimeout(()=>{
                    window.location.reload()
                },1000)
                
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       
       }
       
  return (
    <div>
      
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>


<div style={{ marginLeft: "15px", marginRight: "15px" }}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                  

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">Add Group Name</h2>
                        </div>
                    </div>
                </div>
              
            </div>
            
    </section>

    <div class="modal" id="addnewpincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Group</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Group Name: <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="" placeholder="Enter Name" name="group_name" onChange={(e) => handleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary" onClick={(e)=>create_group(e)} >Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    <section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            
    
            <section>
                <div class="row mb-3">
                    
                    <div class="col-md text-end ">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addnewpincode_special" data-bs-backdrop="false">Add Group</button>
                        {/* <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#import_pincode_special" data-bs-backdrop="false">Import Bulk Pincode</button>
                        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#Import_Log" data-bs-backdrop="false">Import Log</button> */}
                    </div>
                </div>
                <table class="table table-bordered">
                  
                  
                    <thead class="bg-dark">
                      <tr>
                        <th className="text-white">Group ID</th>
                        <th className="text-white">Group Name</th>
                        <th className="text-white">Action</th>
                      
                      </tr>
                    </thead>
                <tbody>
                    {state.map((sub)=>(
                        <tr>
                        <td> <a href={`/group_pincodes/${zone_id.zone_id}/${sub.group_id}`}>{sub.group_id}</a></td>
                         <td>{sub.group_name}</td>
                         <td class="pin_delte">
                            <button type="button" class="btn btn-danger btn-sm" onClick={(e)=>remove(sub.group_id)}><i class="material-icons-outlined pin_delte fs-12">delete</i> </button>
                        </td>
                         </tr>
                  ))}
                </tbody>
               
                </table>
            </section>

            

{/* <TablePagination
                component="div"
                rowsPerPageOptions={[5,10]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        /> */}
          </div>
        </div>
    </section>
    </div>
    </section>
    </div>
  )
}

export default Local_zone
