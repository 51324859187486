import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Zone_management = () => {
    let navigate = useNavigate()
    let {customer_id} = useParams();
    const [productList, setproductList] = useState([]);
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        getZoneList()
    },[])

    const getZoneList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/system_zones`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
         setproductList(res.data.output)


        }).catch((e) => {


        });
    }

  return (

    <div>
    
    <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?> 
         */}
    <Sub_header/>

    <div class="row py-4">
        <div class="col-12 col-md">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li class="breadcrumb-item">
                        <a href="#">
                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Zone List</li>
                </ol>
            </nav>
            <h2 class="h4">All Zones</h2>
        </div>
        <div class="col-12 col-md-5">
            
        </div>
        <div class="col-12 col-md text-end">
            
        </div>
    </div>
    
            
            
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover carr_list_ad">
                    <thead>
                        <tr>
                            <th class="border-gray-200">#</th>	
                            <th class="border-gray-200">Zone Name</th>
                        </tr>
                    </thead>

                    <tbody>
                        {productList.map((sub , i)=>(
                        <tr>
                            <td>
                                {i+1}
                            </td>                     
                            <td>
                                <strong><a href={sub.zone_id=="1297080901"?`/special_zone/${sub.zone_id}`:sub.zone_id=="1247000986"?`/metro_to_metro/${sub.zone_id}`:sub.zone_id=="1234788986"?`/local_zone/${sub.zone_id}`:"#"}>{sub.zone_name}</a></strong>
                            </td> 
                        </tr> 
                        ))}
                                            
                    </tbody>
                   
                </table>
            </div> 
                    

    </section>
   
  
    </div>
  )
}

export default Zone_management
