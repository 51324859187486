import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Servicable_pincodes = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    const[editmodals,setEditModals] = React.useState({show: false, carrier_id:"" ,carrier_name:"",carrier_image:"",})
    const[addImg,setAddImg]=React.useState({uploadPhoto:""})
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
     const[statusState, setstatusState] = React.useState({status:1})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [selectCarrier, setSelectCarrier] = useState({ carrier_id:"" });


  console.log("statusState",statusState)
  
     const [isImageSelected, setIsImageSelected] = React.useState(false);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

     const toggleSidebar = () => {
         setIsSidebarOpen(!isSidebarOpen);
         const homeSection = document.querySelector('.home-section');
         if (homeSection) {
             homeSection.classList.toggle('sidebar-closed');
         }
     };
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_list`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
            setcarrierList(res.data.output)


        }).catch((e) => {


        });
    }
     
 

      const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getCarrierList(newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }

    const handleChange = (e,carrier_id) =>{
        console.log("id---> ", carrier_id);

        console.log("e---> ", e.target.name);
        console.log("e----> ", e.target.checked);
        changeStatus({carrier_id:carrier_id,status:(e.target.checked)? 1 : 0})
    }
    const changeStatus = (updateData) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_status_carrier`;
        let sendData = {carrier_id:updateData.carrier_id , status:updateData.status };
        console.log("sendData",sendData)
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: "Updated Sucessfully",
                color:"white"
              });
          }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                background:"#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color:"white"
              });
               
            
               });
    }
    const csvinputEditHandleChange = (e) => {        
      if (e.target.type == "file") {            
          setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
      } else {
          setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
      }
    }
    const uploadPincodeFile= () => {
     if(selectCarrier.carrier_id!=undefined && selectCarrier.carrier_id!=""){
      if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
         // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
          let fileName = uploadModalState.excel_fle[0].name;
         // console.log("fileName =======>                 ",fileName);
         
         // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
         // return 
          if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_carrier_servicable_pincode`;
       let bodyFormData = new FormData()
      // console.log("bb", editState);
      //  bodyFormData.append("pincode", editState.country)
       bodyFormData.append("carrier_id",selectCarrier.carrier_id )
       bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
       
     // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
      console.log("bb", bodyFormData);
      axios.post(full_api, bodyFormData, {
          headers:exportValue.headers
      }).then((res) => {
          console.log("res ", res);

          setUploadModalState({...uploadModalState, show: false });
        //  setEditState({ ...editState, state_name: "", state_id: "" })

         
          if (res.status == 200 && res.data.status == "success") {
           
             // setTimeout(getStateList(0,true), 5000);
             const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
          })
              Toast.fire({
                  background: "#206bc4",
                  type: 'success',
                  title: "Bulk Import Process Starts",
                  color: "white"
              });
              setTimeout(()=>{
                navigate(`/servicable_pincode_import_process/${selectCarrier.carrier_id}`)
              },1000)
              
          }
          
          else {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',

              })
          }

          // navigate('/categories')
          // let newCategory = res.data.category;
          //setState(state.concat([newCategory]))
      }).catch((e) => {

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',

          })
      });
  }else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
  })
      Toast.fire({
          background: "#8a2be2",
          type: 'error',
          title: "Please choose CSV file!",
          color: "white"
      });
  }
  }else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
  })
           Toast.fire({
                  background: "#10b93b",
                  type: 'error',
                  title: "Please choose a file!",
                  color: "white"
              });
  }
}else{
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
})
         Toast.fire({
                background: "#10b93b",
                type: 'error',
                title: "Please Select Carrier",
                color: "white"
            });
}
 

}
const selectCarrierChange =(e)=>{
   setSelectCarrier({...selectCarrier , carrier_id:e.target.value})
}
    
  return (
    <div>
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
      
      

<div class="row py-4">
    <div class="col-12 col-md">
      
        <h2 class="h4">All Carriers</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
   
</div>



        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#ID</th>	
                        <th class="border-gray-200">Carrier Name</th>
                       
                    </tr>
                </thead>
                {carrierList.map((sub,index)=>(
                <tbody>
                    {/* <!-- Item --> */}
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                            <span class="fw-normal"><a href={`/servicable_pincodes/${sub.carrier_id}`}>{sub.carrier_id}</a></span>
                        </td>                        
                        <td>
                            <strong>{sub.carrier_name}</strong>
                        </td>                        
                        <td class="">
                        <img src={`https://cdn.shiprath.com/images/${sub.carrier_image}`} style={{width:"60px"}} className="mt-3"/>
                       <br/> <small className="text-danger">{sub.carrier_name}</small> 
                        </td>
                      
                    </tr>
                                                
                </tbody>
                ))}
            </table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5,10]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
        </div> 
                

</section>




    </div>
  )
}

export default Servicable_pincodes
