import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';

const Assign_international_zone = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[addzone ,setaddzone] = React.useState({zone_name:""})
    const[state , setstate]=React.useState([])
    const[countries , setcountries]=React.useState([])
    const [zoneSelections, setZoneSelections] = useState({});


    React.useEffect(()=>{
        group_list()
        all_country_list()
    },[])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   

      const handleChange =(e)=>{
        setaddzone({...addzone , [e.target.name]:e.target.value})
      }
      const group_list = ()=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/international_zone_list`;
        let sendData = { }
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                setstate(res.data.output)
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
     
       }
   
       const all_country_list = ()=>{
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/international_zone_country_list`;
        let sendData = { }
        
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
             if(res.data.status == true){
                setcountries(res.data.output)
             }
             else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
             }
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
     }

     const handleZoneChange = (country_id, selectedZone) => {
        setZoneSelections(prev => ({
          ...prev,
          [country_id]: selectedZone
        }));
      };
    
      // Handle Save button click
      const handleSave = () => {
        let selectedData = Object.entries(zoneSelections).map(([country_id, zone_id]) => ({
          country_id,
          zone_id
        }));
    
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/assign_zone_to_country`;
        axios.post(full_api, { countries: selectedData }, { headers: exportValue.headers })
          .then((res) => {
            if (res.data.status==true) {
              Swal.fire({
                title: "Zones assigned successfully",
                icon: "success",
                confirmButtonText: "OK"
              });
              window.location.reload();
            } else {
              Swal.fire({
                title: "Something went wrong",
                icon: "error",
                confirmButtonText: "OK"
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error updating zones",
              icon: "error",
              confirmButtonText: "OK"
            });
            console.error("Error updating zones", error);
          });
      };
       
  return (
    <div>
      
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
              
       <Sub_header/>


<div style={{ marginLeft: "15px", marginRight: "15px" }}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                  

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">Assign International Zone</h2>
                        </div>
                    </div>
                </div>
              
            </div>
            
    </section>

   
    <section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            
              <div class="">
                <table class="table table-hover  table-responsive">
                    <thead>
                        <tr>
                           
                            <th class="border-gray-200">Country Name</th>
                            <th class="border-gray-200">Select Zone</th>

                        </tr>
                    </thead>

                    <tbody>
    {countries.map((country) => (
      <tr key={country.country_id}>
        <td>{country.country_name}</td>
        <td>
          <select className="form-control"
            value={zoneSelections[country.country_id] || country.international_assigned_zone || ""}
                          onChange={(e) => handleZoneChange(country.country_id, e.target.value)}
          >
            <option>Select</option>
            {state.map((zone) => (
              <option value={zone.international_zone_id} key={zone.international_zone_id}>
                {zone.international_zone_name}
              </option>
            ))}
          </select>
        </td>
      </tr>
    ))}
  </tbody>
                </table>
            </div> 
    </div>
           <div className="mt-1 me-3 mb-2 text-end">
            <button className="btn btn-lg btn-primary" onClick={(e)=>handleSave(e)}>Save</button></div>
        </div>
        
    </section>
     
    </div>
    </section>
    </div>
  )
}

export default Assign_international_zone
