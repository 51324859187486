import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
const Special_zone = () => {
    let navigate = useNavigate();
    const { zone_id } = useParams();
    const [zonesave , setzonesave] = useState({zone_name:""})
    const [catstate , setcatstate]  = useState([])
    const [zonestate , setzonestate]  = useState([])
    const[ addpincode , setaddpincode] = useState({pincode:"",destination_category:"" , zone:"" , expected_time:"" , prepaid:0 , cod:0,reverse_pickup:0 , forward_pickup:0})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const[sourcecitylist,setsourceListstate] = useState([])
    const[tempsourcecitylist,settempsourceListstate] = useState([])
    const [searchfilters, setsearchFilters] = React.useState({ searchtitle: ""})
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const[cpscstate , setcpsc] = React.useState({carrier_name:[] , product_name:[] , source_city_name:[]})
    const[stateLoading,setLoading]=React.useState({isLoading:true})
    const[modals , setModals]= React.useState({show:false})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
   
        source_destinationlist()
        
       
      },[])
    const source_destinationlist = (index=0)=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/special_zone_list`;
        let sendData = { indexValue:index,
          limit:otherStates.rowsPerPage }
          if(searchfilters.searchtitle != ""){
            sendData.find= searchfilters.searchtitle
        }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
          if(index==0  && otherStates.onload) {
            console.log("res === " , res)
            setOtherState({...otherStates,total_count:res.data.count})         
  }
                 setsourceListstate(res.data.output)
                 settempsourceListstate(res.data.output)
  
                 setLoading({...stateLoading,isLoading:false})
               
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       }
    const csvinputEditHandleChange = (e) => {        
        if (e.target.type == "file") {            
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        } else {
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
        }
      }

      const uploadPincodeFile= () => {

        if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
           // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = uploadModalState.excel_fle[0].name;
           // console.log("fileName =======>                 ",fileName);
           
           // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
           // return 
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_special_zone`;
         let bodyFormData = new FormData()
        // console.log("bb", editState);
        // bodyFormData.append("country", editState.country)
         bodyFormData.append("zone_id", zone_id)
       

        
        bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
         
       // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
        console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);

            setUploadModalState({...uploadModalState, show: false });
          //  setEditState({ ...editState, state_name: "", state_id: "" })

           
            if (res.status == 200 && res.data.status == "success") {
             
               // setTimeout(getStateList(0,true), 5000);
               const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Bulk Import Process Starts",
                    color: "white"
                });
                setTimeout(()=>{
                  navigate(`/special_zone_import_process/${zone_id}`)
                },1000)
                
            }
            
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }

            // navigate('/categories')
            // let newCategory = res.data.category;
            //setState(state.concat([newCategory]))
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })
        });
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
   

}
const handlePageChange = (event,newPage) =>{
    console.log("newpage",newPage)
    setOtherState({...otherStates,page:newPage})
    //console.log("newPage ", newPage);
    source_destinationlist(newPage);
    
    // searchUser(state.searchValue,newPage)
  }
  const handleChangeRowsPerPage = (event,newPage) => {
    console.log("event ", event);
    setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
   
  }
  return (
    <div>
     <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
<div class="modal" id="import_pincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Bulk Pincode  (Special)</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="https://cdn.shiprath.com/sample-template/Special_zone.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
        {/* <hr/>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Pincode:</label>
          <select class="form-select">
            <option>Global</option> */}
            {/* <option>Delhivery</option>
            <option>DTDC</option>
            <option>ECOM</option> */}
          {/* </select>
        </div> */}
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div style={{ marginLeft: "15px", marginRight: "15px" }}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Zones List</li>
                        </ol>
                    </nav>
                    

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">Special Zone</h2>
                        </div>
                    </div>
                </div>
                <div class="w-25">
                    <select class="form-select">
                      <option>Global Speical Zone</option>
                      {/* <option>DTDC Speical Zone</option>
                      <option>Delhivery Speical Zone</option>
                      <option>Ecom Speical Zone</option> */}
                    </select>
                </div>
            </div>
            
    </section>

    <section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            
    
            <section>
                <div class="row mb-3">
                    <div class="col-md-4 mx-auto">
                        <div class="input-group">
                            <input class="form-control border-end-0 border " type="search" value="" id="example-search-input" placeholder="Search pincode" />
                            <span class="input-group-append">
                                <button class="btn btn-outline-primary bg-white border-bottom-0 border ms-n5" type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="col-md text-end ">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addnewpincode_special" data-bs-backdrop="false">Add New Pincode</button>
                        <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#import_pincode_special" data-bs-backdrop="false">Import Bulk Pincode</button>
                        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#Import_Log" data-bs-backdrop="false">Import Log</button>
                    </div>
                </div>
                <table class="table table-bordered">
                  
                  
                    <thead class="bg-dark">
                      <tr>
                        <th>DESTINATION PINCODE</th>
                        <th>City</th>
                        {/* <th>Additive</th> */}
                        <th>State</th>
                        <th>Country</th>
                        <th>Time</th>
                        <th>PREPAID</th>
                        <th>COD</th>
                        <th>REVERSE PICKUP</th>
                        <th>FORWARD PICKUP</th>
                        <th></th>
                      </tr>
                    </thead>
                    {(stateLoading.isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> :
                    <tbody>
                      {sourcecitylist.map((sub,index)=>(
                      <tr>
                        <td>{sub.postal_code}</td>
                        <td>{sub.city_name}</td>
                        {/* <td>{sub.additive?sub.additive:"-----"}</td> */}
                        <td>{sub.state_name}</td>
                        <td>{sub.country_name}</td>

                        <td>
                           -----
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="prepaid" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td class="pin_delte">
                            <button type="button" class="btn btn-danger btn-sm"><i class="material-icons-outlined pin_delte fs-12">delete</i> </button>
                        </td>
                      </tr>
                       ))}
                    </tbody>
}
                   
               
                </table>
            </section>

                {/* <!-- <div class="text-end mt-3">
                    <button type="button" class="btn btn-primary btn-lg">Save Pricing</button>
                </div> --> */}

<TablePagination
                component="div"
                rowsPerPageOptions={[5,10]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
          </div>
        </div>
    </section>
    </div>
    </section>
    </div>
  )
}

export default Special_zone
