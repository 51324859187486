import React from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"
import { el } from 'date-fns/locale'



const Tickets = () => {

  const [state, setState] = React.useState({
    ticket_list:[],
    dataCount: 0,
    isLoading: true,
    dialogOpen: false,
    searchValue: "",
   

})
const[searchstate , setSearchstate] = React.useState({search:""})
const [shipmentstate, setshipStatus] = React.useState({ status: null });
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
const[editmodals,setEditModals] = React.useState({show: false, ticket_id:"" ,subject:"",ticket_createdById:"",shipment_id:"",date:"",time:"",details:"",full_name:"",ticket_branch_name:"",reply_detail:[],transporter_id:"",user_id:"", full_name_transporter:""})
const [reply, setReply] = React.useState({  reply_content:"",reply_id:"",ticket_id:"" })
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
let admindata = {};
if (localStorage.getItem('admin_login')) {
  admindata = JSON.parse(localStorage.getItem('admin_login'));
  console.log("admindata------>  -  ",admindata);
  //navigate('/home') 
}
let admin = admindata.adminid
console.log("admin_id",admin)
const axios_get_api = (status,index=0,onLoad) => {
    //  console.log("dstatus--->",status)
     console.log("index--->",index)

  
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/ticket_list`;
    let sendData = {
         indexValue: index,
        limit:otherStates.rowsPerPage
    };
    if(searchstate.search!=""){
        sendData.find = searchstate.search
    }
    if(searchfilters.filterStatus.length>0){
        sendData.status = searchfilters.filterStatus
    }
     console.log("send",sendData)
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        console.log("index",index)
        console.log("onLoad",onLoad)
        console.log( "response",res);
// if(res.data.ouptut.length>0){
        if(index==0  && onLoad) {
            setOtherState({...otherStates,total_count:res.data.dataCount})         
}
       
     setState({ ...state, ticket_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
     if(res.data.output.length>0){
        const urlSearchParams = new URLSearchParams(window.location.search);
        console.log("urlSearchParams == " , urlSearchParams)
        // Get and set the customer_id from the URL
        const urlIndex = urlSearchParams.get('ticket_id');
        if(urlIndex){
            const ticketData = res.data.output.find(ticket => ticket.ticket_id === urlIndex);
            console.log("ticketData == " , ticketData)
            if (ticketData) {
                // Set the modal data with the specific ticket from the URL
                setEditModals({
                    ticket_id: ticketData.ticket_id,
                    ticket_createdById: ticketData.ticket_createdById,
                    subject: ticketData.subject,
                    shipment_id: ticketData.shipment_id,
                    details: ticketData.details,
                    full_name: ticketData.full_name,
                    status: ticketData.status,
                    date: ticketData.date,
                    time: ticketData.time,
                    reply_detail: ticketData.reply,
                    customer_id: ticketData.customer_id
                });
            }
        }
        else{
            let sub = res.data.output[0]
            setEditModals({ticket_id:sub.ticket_id, ticket_createdById : sub.ticket_createdById ,subject:sub.subject, shipment_id:sub.shipment_id,details:sub.details,full_name:sub.full_name,status:sub.status,date:sub.date,time:sub.time,reply_detail:sub.reply, customer_id:sub.customer_id})
        }
       
    
     }
     else{
        setEditModals({ticket_id:"", ticket_createdById : "" ,subject:"", shipment_id:"",details:"",full_name:"",status:"",date:"",time:"",reply_detail:[], customer_id:""})
     }
    }).catch((e) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
        console.log("----error:   ", e);
    })

}

React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log("urlSearchParams == " , urlSearchParams)
    // Get and set the customer_id from the URL
    const urlIndex = urlSearchParams.get('index');
    if(urlIndex){
        axios_get_api(null,urlIndex,true)
    }
    else{
  axios_get_api(null,0,true)
    }
}, [])
React.useEffect(() => {
    axios_get_api(null,0,true)
  }, [searchfilters])

const shipmentFilter = (status) => {
  console.log("action ",status);
  setshipStatus({ ...shipmentstate, status: status })

  axios_get_api(status,0,true)
  
}

const handlePageChange = (event,newPage) =>{
  console.log("newpage",newPage)
  setOtherState({...otherStates,page:newPage})
  //console.log("newPage ", newPage);
  axios_get_api(shipmentstate.status,newPage);
  
  // searchUser(state.searchValue,newPage)
}
const handleChangeRowsPerPage = (event,newPage) => {
  console.log("event ", event);
  setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
 
}

const nameFormat = (name)=> {
        console.log("name === " , name)
  if(name) {
      let nameArray =  name.split(" ");
     // console.log("nameArray  ",nameArray);
      if(nameArray.length > 1) {
          let n1 = nameArray[0].substring(0,1).toUpperCase();
          let n2 = nameArray[1].substring(0,1).toUpperCase();
          name = n1+n2;
      }else {
          name = nameArray[0].substring(0,2).toUpperCase();
          
      }
  }else{
      name = "NO";
  }
  return name;
}

const handleChange = (event) => {
  console.log(event);  
  setReply({
      ...reply,
      [event.target.name]: event.target.value,
    });
  
}

const handleSupportReply = (ticket_id) => {
  console.log("ticket_id",ticket_id)
    
  console.log("submit1");
 
  let state_detailes = {reply_content:reply.reply_content,reply_id:"", ticket_id:editmodals.ticket_id}
 
  if(admindata.adminid) {
    state_detailes.reply_id = admindata.adminid;
  }
 

  console.log("report detailes is ",state_detailes);
 
  axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/add_reply`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
          console.log("result is i ",result);
         
          if(result && result.data != undefined){
             
            Swal.fire(
              'Good job!',
              ' Reply Sent  Successfully !',
              'success'
            )
               setEditModals({...editmodals,show:false})
               setReply({...reply,reply_content:""})
               setsearchFilters({filterStatus:[]})
              axios_get_api(null,otherStates.page,true)
                  
              }
              else{
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                  
                })
              }

       }).catch((e) => {
     

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          
        })
        console.log("----error:   ", e);
      });
}
const closeTicket = (ticket_id) => {

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/closeTicket`;
  let sendData = {ticket_id:ticket_id};
  // console.log("bb", sendData);
  axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res ", res);
      Swal.fire(
          'Success!',
          'Ticket Successfully Closed!',
          'success'
        )
        setEditModals({...editmodals,show:false})
        setsearchFilters({filterStatus:[]})
       axios_get_api(null,otherStates.page,true)
    //  setState(res.data.output)
     // setOtherState({ ...otherStates, total_count: res.data.count });
  }).catch((e) => {
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          
        })
   
    console.log("----error:   ", e);

  });

}
const deleteConfirm = (ticket_id) => {
  Swal.fire({
      title: 'Are you sure?',
      text: "You want to close this ticket!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
  }).then((result) => {
      //console.log("result  ",result);
      if(result.isConfirmed) {
          closeTicket(ticket_id)
      }       
  })

}
    
const setDetail = (sub)=>{
    setEditModals({ticket_id:sub.ticket_id, ticket_createdById : sub.ticket_createdById ,subject:sub.subject, shipment_id:sub.shipment_id,details:sub.details,full_name:sub.full_name,status:sub.status,date:sub.date,time:sub.time,reply_detail:sub.reply, customer_id:sub.customer_id})
    const params = new URLSearchParams(window.location.search);
  params.set("ticket_id", sub.ticket_id);
  params.set("index", otherStates.page);

  // Update the browser's URL
  window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
}

const searchChange =(e)=>{
    setSearchstate({...searchstate,search:e.target.value})
}

const filter=(e,values)=>{
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;
  
    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...searchfilters.filterStatus];
  
    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }
  
    // Update the searchfilters state with the updated filterStatus array
    setsearchFilters((prevFilters) => ({
      ...prevFilters,
      filterStatus: updatedFilterStatus,
    }));
  
    
    
  }
  return (

<div>

       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
          
    <Sub_header/>
            
             <div class="row mt-1 g-2 support_list_lft">
                <div class="col-12 col-md-4 ">
                    <div class="card_ticket_list vh-100-min">
                        <div class="">
                        <h5>Recent Tickets</h5>
                        <div class="row mb-4">
                            <div class="col-12 col-md">
                                <div class="search-box">
                                    <div class="search-icon">
                                        <i class="fa fa-search" onClick={(e)=>axios_get_api(null,0,true)}></i> 
                                    </div>
                                    <input type="text" class="search-input" placeholder="Search ticket..." name="searchValue" onChange={(e)=>searchChange(e)}/>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class='bx bx-filter' ></i> &nbsp;
                                        Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <section class="p-2">
                                        <hr/>
                                            
                                        <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,1)}/>
                                                <label class="form-check-label">Answered</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,0)}/>
                                                <label class="form-check-label">Awaiting Response</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,2)}/>
                                                <label class="form-check-label">Closed</label>
                                            </div>
                                        </section>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ticket_list">
                            <div class="list-group">
                            {state.ticket_list.map((sub,index)=>( 
                                <a  onClick={()=>setDetail(sub,index)} class={editmodals.ticket_id==sub.ticket_id?"list-group-item list-group-item-action active_list":"list-group-item list-group-item-action"}>
                                    <div class="row">
                                        <div class="col-12 col-md">
                                            <h1>{sub.subject}</h1>
                                            <h2>Shipment # {sub.shipment_id!="" && sub.shipment_id!=undefined? sub.shipment_id:""} </h2>
                                        </div>
                                        <div class="col-12 col-md-6 text-end">
                                            <div>
                                                <small class="text-muted"> {sub.date} {sub.time}</small>
                                            </div>
                                            {sub.status==0? <span class="badge_t status_p">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Awating response
                                            </span>:sub.status==1?  <span class="badge_t status_o">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Answered
                                            </span>:sub.status==2? <span class="badge_t status_c">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Closed
                                            </span>:""}
                                           
                                        </div>
                                    </div>
                                   
                                </a>
                            ))}
                              
                            </div>
                        </div>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[20,25,30,35,40,45,50]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md ticket_list_md ticket_list vh-100-min">
                    <div class="row mb-4">
                        <div class="col-12 col-md">
                            <h3>{editmodals.subject} #{editmodals.ticket_id}</h3>
                            <div class="row">
                                <div class="col-12 col-md">
                                    <small>{editmodals.date} {editmodals.time} <i class="fa fa-circle" aria-hidden="true"></i> #{editmodals.shipment_id!=""?"Shipment #":""}<a >{editmodals.shipment_id!=""?editmodals.shipment_id:""}</a> | {editmodals.full_name!=""?"Customer #":""}<a href={`customers_info/${editmodals.ticket_createdById}`}>{editmodals.full_name!=""?(editmodals.ticket_createdById):""}</a></small>
                                </div>
                                {/* <div class="col-12 col-md-3 text-end">
                                    <select class="form-select form-select-sm">
                                        <option>Assign to</option>
                                        <option>Mark Smith</option>
                                        <option>Danial Star</option>
                                        <option>John Doe</option>
                                      </select>
                                </div> */}
                            </div>
                            
                            <div class="mb-3 mt-3">
                                
                            </div>
                        </div>
                        <div class="col-12 col-md-4 text-end">
                        {editmodals.status==0? <span class="badge_t status_p">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Awating response
                                            </span>:editmodals.status==1?  <span class="badge_t status_o">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Answered
                                            </span>:editmodals.status==2? <span class="badge_t status_c">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Closed
                                            </span>:""}
                                            {editmodals.status!=2?
                            <div class="mt-2">
                                <a onClick={()=>deleteConfirm(editmodals.ticket_id)} class="btn btn-danger btn-sm">Mark as Closed</a>
                            </div>
                            :""}
                           
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-body'>
                            <h4>Details</h4>
                            {editmodals.details}
                        </div>
                    </div>
                    

                    <div class="card">
                    {editmodals.reply_detail.map((item)=>(
                        <div class="card-body">
                        
                             {item.reply_id==editmodals.customer_id?
                            <div class="row">
                                <div class="col-12 col-md-1">
                                    <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name)} class="img-fluid" />
                                </div>
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md">
                                            <h3>{editmodals.full_name} (Customer)</h3>
                                        </div>
                                        <div class="col-12 col-md-4 text-end">
                                            <small>{item.date} {item.time}</small>
                                        </div>
                                    </div>
                                    <p>
                                    {item.reply_content}
                                    </p>
                                    {/* <div class="mt-3 flie_attache_support">
                                        <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                        <ul class="">
                                            <li><a href="">filename.pdf</a></li>
                                            <li><a href="">filename.jpg</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            :item.reply_id==editmodals.transporter_id?
                            <div class="row">
                            <div class="col-12 col-md-1">
                                <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name_transporter)} class="img-fluid" />
                            </div>
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h3>{editmodals.full_name_transporter} ({editmodals.ticket_branch_name}) (Transporter)</h3>
                                    </div>
                                    <div class="col-12 col-md-4 text-end">
                                        <small>{item.date} {item.time}</small>
                                    </div>
                                </div>
                                <p>
                                {item.reply_content}
                                </p>
                                {/* <div class="mt-3 flie_attache_support">
                                    <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                    <ul class="">
                                        <li><a href="">filename.pdf</a></li>
                                        <li><a href="">filename.jpg</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                            :  item.reply_id!=editmodals.customer_id?  <div class="row">
                            <div class="col-12 col-md-1">
                                <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(admindata.full_name)} class="img-fluid" />
                            </div>
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h3>{admindata.full_name} (System Admin)</h3>
                                    </div>
                                    <div class="col-12 col-md-4 text-end">
                                        <small>{item.date} {item.time}</small>
                                    </div>
                                </div>
                                <p>
                                {item.reply_content}
                                </p>
                                {/* <div class="mt-3 flie_attache_support">
                                    <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                    <ul class="">
                                        <li><a href="">filename.pdf</a></li>
                                        <li><a href="">filename.jpg</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>:""}
                    
                        </div>
                                ))}
                    </div>
                    {editmodals.status!=2 ?
                    <div class="py-3 comment_box">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    {/* <div class="col-12 col-md-1">
                                        <img src="https://i.imgur.com/3228qEZ.png" class="img-fluid" />
                                    </div> */}
                                    <div class="col-12 col-md">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                
                                            </div>
                                            <div class="col-12 col-md-4 text-end">
                                                <small></small>
                                            </div>
                                        </div>
                                        <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
                                        <div class="row mt-2">
                                            {/* <div class="col-12 col-md-5">
                                                <input type="file" class="form-control" id="text" placeholder="Enter details name="/>
                                            </div> */}
                                            <div class="col-12 col-md text-end">
                                                <div class="">
                                                    <a  class="btn btn-primary" onClick={()=>handleSupportReply({ticket_id:editmodals.ticket_id})}>Send</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :""}
                </div>
             </div>
                    

    </section>

{/* <?php
include('footer.php');
?>   */}
</div>
  )
}

export default Tickets